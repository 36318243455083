// extracted by mini-css-extract-plugin
export var category = "Feed-module--category--WfWA4";
export var description = "Feed-module--description--VzSOs";
export var divider = "Feed-module--divider--gaGDJ";
export var feed = "Feed-module--feed--piBJn";
export var item = "Feed-module--item--x6Y5v";
export var link = "Feed-module--link--YSO-D";
export var meta = "Feed-module--meta--JQwiD";
export var more = "Feed-module--more--UaTkA";
export var time = "Feed-module--time--coZK5";
export var title = "Feed-module--title--8lL66";